import * as zod from 'zod';

import type { GlobalVariableData } from '@sb/feathers-types';
import { Expression } from '@sb/routine-runner/types/expressions/Expression';

import type { EnvironmentVariable } from '../types/environment-variable';

export const EnvironmentVariableSchema = zod.object({
  id: zod.string(),
  name: zod.string(),
  initialValue: Expression.optional(),
});

export type EnvironmentVariableType = zod.infer<
  typeof EnvironmentVariableSchema
>;

export function convertGlobalVariableResponse(
  globalVariableData: GlobalVariableData,
): {
  globalVariableId: string;
  globalVariableItem: EnvironmentVariable;
} {
  const parsedItem = EnvironmentVariableSchema.safeParse(
    globalVariableData.globalVariableItem,
  );

  if (parsedItem.success) {
    const variableData = parsedItem.data;

    return {
      globalVariableId: globalVariableData.id,
      globalVariableItem: { ...variableData, isGlobal: true },
    };
  }

  throw new Error('Failed to parse item');
}
