import * as zod from 'zod';

import { Expression } from '@sb/routine-runner/types/expressions/Expression';

export const GlobalVariableData = zod.object({
  id: zod.string(),
  globalVariableItem: zod
    .object({
      id: zod.string(),
      name: zod.string(),
      initialValue: Expression.optional(),
    })
    .passthrough(), // additional props are valid
  created: zod.string(),
  updated: zod.string(),
});
export type GlobalVariableData = zod.infer<typeof GlobalVariableData>;
